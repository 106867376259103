import { graphql } from 'gatsby'
import React from 'react'
import BannerGeneric from '../components/BannerGeneric'
import { useSlack } from '../components/hooks'
import Layout from '../components/layout'
import ProfileDetails from '../components/ProfileDetails'
import RestrictedButton from '../components/RestrictedButton'
import { BB_HR } from '../utils/constants'
import { BloomBMember } from '../utils/types'

const profile = ({
  data: { contentfulMember: member },
}: {
  data: { contentfulMember: BloomBMember }
}) => {
  useSlack(`Profile details of ${member.name}`)
  return (
    <Layout>
      <div className="inner">
        <BannerGeneric
          title={member.name as string}
          description={`${member.title} ( ${member.qualification} ) | ${member.experience}`}
        >
          <ul className="actions">
            <li>
              <RestrictedButton
                text="Schedule Appointment"
                roles={[BB_HR]}
                linkTo={`/app/schedule`}
                state={{
                  calendly: member.calendly,
                  accessToken: member?.calendlyAccessToken.calendlyAccessToken,
                  username: member.email,
                }}
              />
            </li>
          </ul>
        </BannerGeneric>
        <ProfileDetails data={member} />
      </div>
    </Layout>
  )
}

export default profile

export const memberQuery = graphql`
  query memberBySlug($slug: String!) {
    contentfulMember(slug: { eq: $slug }) {
      id
      slug
      address
      bloomBProfile
      experience
      contentful_id
      email
      facebook
      name
      phone
      qualification
      registrations
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      title
      twitter
      website
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        file {
          url
        }
      }
      calendly
      calendlyAccessToken {
        calendlyAccessToken
      }
    }
  }
`
