import { Link } from 'gatsby'
import React from 'react'
import { useApprovedContent } from './hooks'

interface RestrictedButtonProps {
  linkTo: string
  text: string
  roles: string[]
  state?: any
  target?: boolean
}
const RestrictedButton: React.FC<RestrictedButtonProps> = ({
  linkTo,
  text,
  roles,
  state,
  target,
}) => {
  const [display] = useApprovedContent(roles)

  return (
    <>
      {display && (
        <Link
          to={linkTo}
          className="button special"
          state={state}
          target={target ? `_blank` : ``}
        >
          {text}
        </Link>
      )}
    </>
  )
}

export default RestrictedButton
