import React from 'react'
import { handleWebHook } from '../utils'

interface WhatsappProps {
  phone: string
  text?: string
  label: string
  classnames: string
  icon?: boolean
}
const Whatsapp: React.FC<WhatsappProps> = ({
  phone,
  text,
  label,
  classnames,
  icon,
}) => {
  return (
    <a
      href={`https://wa.me/91${phone}?text=${text}`}
      className={classnames}
      target="_blank"
      style={{ color: '#25D366' }}
      onClick={() =>
        handleWebHook({
          text: `Someone just pressed Whatsapp Contact with ${text}...`,
        })
      }
    >
      {icon && (
        <>
          <span className="icon fa-whatsapp fa-lg" style={{ color: '#25D366' }}>
            <span className="label">Twitter</span>
          </span>
          &nbsp;
        </>
      )}
      {label}
    </a>
  )
}

export default Whatsapp
