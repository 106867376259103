import React from 'react'
import { BloomBMember } from '../utils/types'
import { Seo } from './Seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BB_BASE_URL } from '../utils/constants'
import Whatsapp from './Whatsapp'
import { Link } from 'gatsby'

const ProfileDetails = ({ data }: { data: BloomBMember }) => {
  return (
    <>
      <Seo
        title={data.name}
        socialImage={`https:${data.image.file.url}`}
        description={data.shortBio.shortBio}
        keywords={`${data.name}`}
        metaUrl={`${BB_BASE_URL}/profile/${data.slug}`}
      />
      <section className="profiledetails">
        <div className="inner">
          <section>
            {data.specializations && (
              <h4>Specialization : {data.specializations}</h4>
            )}
            <div>
              <span className="image right">
                <GatsbyImage
                  image={getImage(data.image.localFile)}
                  alt={data.name as string}
                />
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: data.shortBio.childMarkdownRemark.html,
                }}
              ></span>
            </div>
            <ul className="icons">
              <li>
                <a
                  href={`https://twitter.com/${data.twitter}`}
                  className="icon fa-twitter"
                >
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/${data.facebook}`}
                  className="icon fa-facebook"
                >
                  <span className="label">Facebook</span>
                </a>
              </li>
            </ul>
          </section>
          <section className="split">
            {data.email && (
              <section>
                <div className="contact-method">
                  <span className="icon alt fa-envelope"></span>
                  <h3>Email</h3>
                  <a href="/#">{data.email}</a>
                </div>
              </section>
            )}
            {data.phone && (
              <section>
                <div className="contact-method">
                  <span className="icon alt fa-whatsapp"></span>
                  <h3>Phone</h3>
                  <Whatsapp
                    phone={data.phone}
                    text={`Hello%20${data.name},%20Hope%20you%20are%20well.%20I'm%20looking%20for%20medical%20advice%20on%20...`}
                    label="Contact Me"
                    classnames="button scrolly"
                  />
                </div>
              </section>
            )}
            {data.address && (
              <section>
                <div className="contact-method">
                  <span className="icon alt fa-home"></span>
                  <h3>Address</h3>
                  <span>{data.address}</span>
                </div>
              </section>
            )}
            {data.email === 'drshabana@bloomb.co.in' && (
              <section>
                <div className="contact-method">
                  <span className="icon alt fa-file"></span>
                  <h3>BBMO</h3>
                  <Link to="/track" className="button special">
                    Track
                  </Link>
                </div>
              </section>
            )}
            {/* {data.website && (
              <section>
                <div className="contact-method">
                  <span className="icon alt fa-chrome"></span>
                  <h3>Website</h3>
                  <span>{data.website}</span>
                </div>
              </section>
            )} */}
          </section>
        </div>
      </section>
    </>
  )
}

export default ProfileDetails
